import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/index/banner"
import Vitrin from "../components/index/vitrin"


const IndexPage = () =>{
    useEffect(() => {
      sessionStorage.setItem('lang','tr')
    })
  
    return (
      <Layout>
        <SEO title="Anasayfa" />
    
        <Banner />
        <Vitrin />
    
      </Layout>
    )
  }
export default IndexPage
